// @flow
import classnames from "classnames";
import * as React from "react";
import PreviewLoader from "core/components/PreviewLoader";
import { Abstract } from "core/lib/abstract";
import type {
  LayerData as TLayerData,
  Layer as TLayer,
  LayerLink,
  ReactRouterLocation,
  BranchCollectionDescriptor,
} from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  layerData: TLayerData,
  link: LayerLink,
  descriptor: Abstract.LayerVersionDescriptor | BranchCollectionDescriptor,
  location: ReactRouterLocation,
  onClickTarget?: (link: LayerLink) => void,
  scale: number,
|};
export type StateProps = {|
  isMissing: boolean,
  targetLayer: ?TLayer,
|};

export type Props = { ...OwnProps, ...StateProps };

type Rect = $ReadOnly<{
  x: number,
  y: number,
  width: number,
  height: number,
}>;

export function HotspotLayer(props: Props) {
  const { targetLayer, layerData, isMissing } = props;

  const handleClickHotspot = (layer: ?TLayerData) => {
    if (layer && layer.properties.link && props.onClickTarget) {
      props.onClickTarget(layer.properties.link);
      return;
    }
  };

  return targetLayer ? (
    <PreviewLoader
      projectId={targetLayer.projectId}
      fileId={targetLayer.fileId}
      commitSha={targetLayer.lastChangedAtSha}
      layerId={targetLayer.id}
    >
      {(src, loading, error) => {
        return (
          <React.Fragment>
            <div
              data-name={layerData.properties.name}
              style={rectStyle(layerData.properties, props)}
              onClick={(event) => {
                event.stopPropagation();
                if (!loading) {
                  handleClickHotspot(layerData);
                }
              }}
              className={classnames(style.rect, {
                [style.disabled]: isMissing,
              })}
            />
            {src && (
              <img
                src={src}
                className={style.hiddenPreloadedImage}
                alt="preview"
              />
            )}
          </React.Fragment>
        );
      }}
    </PreviewLoader>
  ) : null;
}

function rectStyle(
  rect: Rect,
  props: Props
): {
  width: string,
  height: string,
  top: string,
  left: string,
} {
  return {
    width: `${rect.width * props.scale}px`,
    height: `${rect.height * props.scale}px`,
    top: `${rect.y * props.scale}px`,
    left: `${rect.x * props.scale}px`,
  };
}

export default connector(HotspotLayer);
