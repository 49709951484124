// @flow
import * as React from "react";
import Button from "core/components/Button";
import Facepile from "core/components/Facepile";
import PillBadge, { getLabelBadgeType } from "core/components/PillBadge";
import UserListItem from "core/components/UserListItem";
import { getRoleLabel } from "core/models/projectMembership";
import type { User, ProjectMembership } from "core/types";
import style from "./style.scss";

type Props = {
  includeAdmins?: boolean,
  reviewerIds: ?(string[]),
  admins: User[],
  users: { [id: string]: ?User },
  roles: { [userId: string]: ?ProjectMembership },
  onRemoveReviewer: (reviwerId: string) => void,
};

function ReviewerList(props: Props) {
  const reviewers =
    props.reviewerIds &&
    props.reviewerIds.map((id) => {
      const user = props.users[id];
      const role = props.roles[id];
      if (!user || !role) {
        return undefined;
      }
      const label = getRoleLabel(role);
      return (
        <UserListItem
          id={id}
          name={user.name}
          username={user.username}
          avatarUrl={user.avatarUrl}
          key={id}
          responsive
          badge={label}
          badgeColor={getLabelBadgeType(label)}
        >
          <Button
            nude
            icon="close"
            onClick={() => props.onRemoveReviewer(id)}
          />
        </UserListItem>
      );
    });

  return (
    <React.Fragment>
      {props.includeAdmins && (
        <div className={style.projectAdminWrapper}>
          <Facepile max={4} users={props.admins} size={32} />
          <PillBadge
            title="All Project Admins"
            type="paleAllGood"
            className={style.projectAdminBadge}
          />
        </div>
      )}
      <ul className={style.reviewerList} data-qa="reviewer-list">
        {reviewers}
      </ul>
    </React.Fragment>
  );
}

export default ReviewerList;
