// @flow
import { connect } from "react-redux";
import createConnector from "core/lib/createConnector";
import {
  getTargetLayerMissing,
  getTargetLayer,
} from "core/selectors/prototypes";
import type { State } from "core/types";
import type { Props, OwnProps, StateProps } from "./";

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const { descriptor } = props;
  const options = { ...props.location.query };

  const isMissing =
    !!props.link.target &&
    getTargetLayerMissing(state, descriptor, props.link, options);

  return {
    isMissing,
    targetLayer:
      props.link.target &&
      getTargetLayer(state, descriptor, props.link, options),
  };
}

export default createConnector<Props, OwnProps>(connect(mapStateToProps));
