// @flow
import * as React from "react";

// This component purposefully uses the non-connected version of Avatar so that
// it can be used in the Quick Commit window which does not have a store.
import { Component as Avatar } from "../Avatar";

type UserSuggestion = {
  id: string,
  name: string,
  username: string,
  avatarUrl: string,
};

type Props = {
  user: UserSuggestion,
  className?: string,
  theme: Object,
};

export default function AutoCompleteListItem(props: Props) {
  const { className, user, theme, ...rest } = props;

  return (
    // receives className of -- theme.mentionSuggestionsEntryFocused | theme.mentionSuggestionsEntry
    <div
      className={className}
      data-qa={`suggestion-list-item-${user.username}`}
      {...rest}
    >
      <div className={theme.mentionSuggestionsEntryContainer}>
        <Avatar userId={user.id} src={user.avatarUrl} userName={user.name} />
        <div className={theme.mentionSuggestionsEntryName}>{user.name}</div>
        <div className={theme.mentionSuggestionsEntryUsername}>
          @{user.username}
        </div>
      </div>
    </div>
  );
}
