// @flow
import classnames from "classnames";
import * as React from "react";
import { Button as ReakitButton } from "reakit/Button";
import PillBadge from "core/components/PillBadge";
import PlaceholderText from "core/components/PlaceholderText";
import TextHighlight from "core/components/TextHighlight";
import type { PillBadgeTypes } from "core/types";
import Avatar, {
  type AvatarBorderColor,
  Placeholder as AvatarPlaceholder,
} from "../Avatar";
import style from "./style.scss";

type Props = {
  id: string,
  name: string,
  highlight?: string,
  username?: string,
  avatarUrl: string,
  avatarBorderColor?: AvatarBorderColor,
  avatarBadgeIcon?: string,
  badge?: string,
  badgeColor?: PillBadgeTypes,
  joinedOn?: string,
  className?: string,
  responsive?: boolean,
  onClick?: Function,
  onContextMenu?: Function,
  children?: any,
  component: "li" | "div",
  innerRef?: React.Ref<React.ElementType>,
};

export function Placeholder({
  className,
  component = "div",
  responsive,
}: {
  className?: string,
  component: "li" | "div",
  responsive?: boolean,
}) {
  const Component = component;

  return (
    <Component
      className={classnames(style.item, className, {
        [style.responsiveItem]: responsive,
      })}
    >
      <AvatarPlaceholder size={32} />
      <div className={style.center}>
        <span className={style.nameWrap}>
          <span className={style.name}>
            <PlaceholderText />
          </span>
        </span>
        <span className={style.username}>
          <PlaceholderText size="s" max={50} />
        </span>
      </div>
    </Component>
  );
}

export default function UserListItem(props: Props) {
  const badge = props.badge && (
    <PillBadge type={props.badgeColor || "paleAllGood"} title={props.badge} />
  );

  return (
    <ReakitButton
      ref={props.innerRef}
      as={props.component}
      tabIndex={0}
      className={classnames(style.item, props.className, {
        [style.responsiveItem]: props.responsive,
        [style.clickable]: props.onClick,
      })}
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
      data-qa={`user-list-item-${props.username || props.id}`}
    >
      <Avatar
        userId={props.id}
        name={props.name}
        src={props.avatarUrl}
        borderColor={props.avatarBorderColor}
        badgeIcon={props.avatarBadgeIcon}
        size={32}
      />
      <div className={style.center}>
        <span className={style.nameWrap}>
          <span className={style.name}>
            <TextHighlight text={props.name} highlight={props.highlight} />
          </span>
          {props.responsive && badge}
        </span>
        {/* Desktop sizes */}
        {props.username && (
          <span className={style.username}>
            @<TextHighlight text={props.username} highlight={props.highlight} />
          </span>
        )}
        {props.joinedOn && (
          <span className={style.joinedOn}>{props.joinedOn}</span>
        )}
        {/* Mobile sizes */}
        {props.responsive && (
          <span className={style.meta}>
            {!!props.username && <span>@{props.username}</span>}
            {!!(props.username && props.joinedOn) && " · "}
            {props.joinedOn}
          </span>
        )}
      </div>
      {!props.responsive && badge}
      {props.children}
    </ReakitButton>
  );
}

UserListItem.defaultProps = {
  component: "li",
};
