// @flow
import idx from "idx";
import * as React from "react";
import Button from "core/components/Button";
import MergeRestrictionsEnterprise from "core/components/Empty/MergeRestrictionsEnterprise";
import FormSection from "core/components/FormSection";
import InputCheckbox from "core/components/InputCheckbox";
import InputSwitch from "core/components/InputSwitch";
import InputUserSelect from "core/components/InputUserSelect";
import Popover from "core/components/Popover";
import Tab from "core/components/ProjectSettings/Tab";
import Select from "core/components/Select";
import SettingsForm from "core/components/SettingsForm";
import SettingsItem from "core/components/SettingsItem";
import { replace } from "core/lib/location";
import { projectEditPath } from "core/lib/routes";
import type { BranchRestrictions, User, ProjectMembership } from "core/types";
import ReviewerList from "./ReviewerList";
import connector from "./connector";
import style from "./style.scss";

const MINIMUM_APPROVALS_MAX = 5; // matches server limit: https://git.io/JvWnr

export type Form = {
  requireProjectAdmin?: boolean,
  requiredReviewerIds?: string[],
  minimumRequiredApprovals?: number,
  dismissReviewsOnCommit?: boolean,
};

export type OwnProps = {|
  params: {
    projectId: string,
  },
|};

export type StateProps = {|
  defaultBranchName: string,
  restrictions: ?BranchRestrictions,
  projectAdmins: User[],
  projectUsers: { [id: string]: ?User },
  projectRoles: { [userId: string]: ?ProjectMembership },
  isOffline: boolean,
  isSubmitting: boolean,
  submitSucceeded: boolean,
  hasError: boolean,
  loading: boolean,
  showMergeRestrictionsCTA: boolean,
  notPermitted: boolean,
|};

export type DispatchProps = {|
  onLoad: () => void,
  onSubmit: (form: Form) => void,
|};

export type Props = {
  ...OwnProps,
  ...StateProps,
  ...DispatchProps,
};

type State = {
  form: Form,
  isDirty: boolean,
};

class ProjectSettingsRestrictions extends React.Component<Props, State> {
  state = {
    form: {},
    isDirty: false,
  };

  componentDidMount() {
    if (this.props.notPermitted) {
      replace(projectEditPath(this.props.params.projectId));
    }

    if (this.props.restrictions) {
      this.setInitialState();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.setInitialState();
    }
  }

  setInitialState = () => {
    this.setState({
      form: {
        requireProjectAdmin:
          idx(this.props, (_) => _.restrictions.requireProjectAdmin) || false,
        requiredReviewerIds:
          idx(this.props, (_) => _.restrictions.requiredReviewerIds) || [],
        minimumRequiredApprovals:
          idx(this.props, (_) => _.restrictions.minimumRequiredApprovals) || 1,
        dismissReviewsOnCommit:
          idx(this.props, (_) => _.restrictions.dismissReviewsOnCommit) ||
          false,
      },
    });
  };

  setForm = (form: $Shape<Form>, callback?: () => mixed) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          ...form,
        },
        isDirty: true,
      },
      callback
    );
  };

  onSelectReviewer = (user: User) => {
    const { requiredReviewerIds = [] } = this.state.form;

    if (!requiredReviewerIds.includes(user.id)) {
      this.setForm({
        requiredReviewerIds: [...requiredReviewerIds, user.id],
      });
    }
  };

  onRemoveReviewer = (reviewerId: string) => {
    const { requiredReviewerIds = [] } = this.state.form;

    this.setForm(
      {
        requiredReviewerIds: requiredReviewerIds.filter(
          (id) => id !== reviewerId
        ),
      },
      this.recalculateMinimumApprovals
    );
  };

  onChangeRequireProjectAdmin = (event: SyntheticInputEvent<>) => {
    this.setForm(
      { requireProjectAdmin: event.target.checked },
      this.recalculateMinimumApprovals
    );
  };

  onChangeMinimumApprovals = (event: SyntheticInputEvent<>) => {
    this.setForm({ minimumRequiredApprovals: parseInt(event.target.value) });
  };

  onChangeDismissOnCommit = (event: SyntheticInputEvent<>) => {
    this.setForm({ dismissReviewsOnCommit: event.target.checked });
  };

  reviewerCount = (): number => {
    const { requireProjectAdmin, requiredReviewerIds } = this.state.form;
    const { projectAdmins } = this.props;

    let reviewerCount = requiredReviewerIds ? requiredReviewerIds.length : 0;
    if (requireProjectAdmin) {
      const dedupedAdmins = requiredReviewerIds
        ? projectAdmins
            .map((u) => u.id)
            .filter((id) => !requiredReviewerIds.includes(id))
        : projectAdmins;
      reviewerCount += dedupedAdmins.length;
    }

    return reviewerCount;
  };

  recalculateMinimumApprovals = (): void => {
    const { minimumRequiredApprovals = 1 } = this.state.form;

    const reviewerCount = this.reviewerCount();
    if (reviewerCount < minimumRequiredApprovals) {
      const delta = minimumRequiredApprovals - reviewerCount;
      this.setForm({
        minimumRequiredApprovals: minimumRequiredApprovals - delta || 1,
      });
    }
  };

  renderApprovalOptions = () => {
    let optionsCount = this.reviewerCount() || 1;
    if (optionsCount > MINIMUM_APPROVALS_MAX) {
      optionsCount = MINIMUM_APPROVALS_MAX;
    }
    return Array.from(Array(optionsCount), (_, i) => {
      const value = i + 1;
      return <option key={value}>{value}</option>;
    });
  };

  onSubmit = (event: SyntheticEvent<>) => {
    event.preventDefault();
    this.props.onSubmit(this.state.form);
    this.setState({ isDirty: false });
  };

  render() {
    const { form } = this.state;
    const {
      defaultBranchName,
      loading,
      isOffline,
      projectAdmins,
      projectUsers,
      projectRoles,
      submitSucceeded,
      hasError,
      isSubmitting,
      showMergeRestrictionsCTA,
    } = this.props;

    const restrictionsEnabled =
      !!form.requireProjectAdmin ||
      (!!form.requiredReviewerIds && form.requiredReviewerIds.length > 0);

    if (showMergeRestrictionsCTA) {
      return (
        <MergeRestrictionsEnterprise defaultBranchName={defaultBranchName} />
      );
    }

    return (
      <Tab
        loading={loading}
        success={submitSucceeded}
        successMessage="Restrictions updated"
        error={hasError}
        errorMessage="Could not update restrictions"
      >
        <SettingsForm className={style.form}>
          <FormSection
            heading="Merge Restrictions"
            description={`Once enabled for this project, all merges to ${defaultBranchName} require approval from one or more of the reviewers listed below. Contributors may add optional reviewers to collect additional feedback when they request a review.`}
          >
            <SettingsItem label="Reviewers" responsive>
              <div className={style.reviewerWrapper}>
                <div className={style.reviewerHelper}>
                  Add reviewers to restrict merges to {defaultBranchName} for
                  all Branches in this project.
                </div>
                <InputUserSelect
                  placeholder="Find a reviewer…"
                  onPick={this.onSelectReviewer}
                  projectId={this.props.params.projectId}
                  excludedUserIds={form.requiredReviewerIds}
                  disabled={isOffline}
                  value={null}
                />
                <InputCheckbox
                  label="Include all Project Admins as reviewers"
                  checkboxClass={style.projectAdminCheckbox}
                  labelClass={style.projectAdminLabel}
                  onChange={this.onChangeRequireProjectAdmin}
                  checked={form.requireProjectAdmin || false}
                  disabled={isOffline}
                />
                <ReviewerList
                  includeAdmins={form.requireProjectAdmin}
                  reviewerIds={form.requiredReviewerIds}
                  admins={projectAdmins}
                  users={projectUsers}
                  roles={projectRoles}
                  onRemoveReviewer={this.onRemoveReviewer}
                />
                <span className={style.note}>
                  Each reviewer will receive individual notifications once a
                  review is requested.
                </span>
              </div>
            </SettingsItem>
            <SettingsItem label="Review approval minimum" responsive>
              <div className={style.minimumApprovalWrapper}>
                <div className={style.dismissReviewHelper}>
                  Set a review approval minimum when Contributors must have
                  approval from more than one reviewer.
                </div>
                <div>
                  <Popover
                    disabled={restrictionsEnabled}
                    placement="left"
                    label="Select reviewers to adjust the approval minimum"
                  >
                    <span className={style.minimumApprovalInput}>
                      <Select
                        disabled={!restrictionsEnabled || isOffline}
                        onChange={this.onChangeMinimumApprovals}
                        value={form.minimumRequiredApprovals}
                        qaSelector="minimum-approvals"
                      >
                        {this.renderApprovalOptions()}
                      </Select>
                    </span>
                  </Popover>
                </div>
              </div>
            </SettingsItem>
            <SettingsItem label="Dismiss reviews" responsive>
              <div className={style.dismissReviewWrapper}>
                <div className={style.dismissReviewHelper}>
                  When a Commit is created on a Branch, automatically dismiss
                  reviews that are pending, approved, or have change requests.
                </div>
                <div>
                  <Popover
                    disabled={restrictionsEnabled}
                    placement="left"
                    label="Select reviewers to adjust review dismissal"
                  >
                    <span>
                      <InputSwitch
                        checked={form.dismissReviewsOnCommit || false}
                        name="dismissOnCommit"
                        disabled={!restrictionsEnabled || isOffline}
                        onChange={this.onChangeDismissOnCommit}
                      />
                    </span>
                  </Popover>
                </div>
              </div>
            </SettingsItem>
            <SettingsItem className={style.submit}>
              <Button
                disabled={!this.state.isDirty || isSubmitting || isOffline}
                onClick={this.onSubmit}
                primary
                type="submit"
              >
                {isSubmitting ? "Saving changes…" : "Save changes"}
              </Button>
            </SettingsItem>
          </FormSection>
        </SettingsForm>
      </Tab>
    );
  }
}

export default connector(ProjectSettingsRestrictions);
