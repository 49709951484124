// @flow
import classnames from "classnames";
import { upperFirst } from "lodash";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "core/components/Button";
import ContextMenu from "core/components/ContextMenu";
import Flex from "core/components/Flex";
import Icon from "core/components/Icon";
import Modal from "core/components/Modal";
import { organizationBillingUrl, helpSeatAndRolesUrl } from "core/lib/urls";
import { UpdateOrganizationMembershipsRequest } from "core/requests/memberships";
import { SubscriptionFetchRequest } from "core/requests/seats";
import { getUserOrganizationMembership } from "core/selectors/memberships";
import type { Subscription, User } from "core/types";
import style from "../style.scss";

type Props = {
  open: boolean,
  close: () => void,
  subscription: Subscription,
  user: User,
  organizationId: string,
};

type ModalPage = "edit" | "confirm";

type ModalOrganizationRole = "member" | "guest" | "admin";

type PermissionChanges = {
  newOrganizationRole: "member" | "admin" | "guest" | false,
  newSeatType: "viewer" | "contributor" | false,
};

const ConfirmationContent = ({
  user,
  changes,
  organizationId,
  subscription,
}: {
  user: User,
  changes: PermissionChanges,
  organizationId: string,
  subscription: Subscription,
}) => {
  const SEAT_DESCRIPTIONS = {
    viewer: (
      <p key="viewer" className={style.seatDescription}>
        Viewers can only see and comment on what is happening in your
        organization. They cannot make any changes.{" "}
        <a href={helpSeatAndRolesUrl()} className={style.copyLink}>
          Learn more
        </a>
      </p>
    ),
    contributor: (
      <p key="contributor" className={style.seatDescription}>
        Contributors can see, comment on, and do work on the projects they have
        access to.{" "}
        <a href={helpSeatAndRolesUrl()} className={style.copyLink}>
          Learn more
        </a>
      </p>
    ),
  };

  const ROLE_DESCRIPTONS = {
    member: (
      <p className={style.roleDescription}>
        Members can access all public projects within an organization, excluding
        private projects.
      </p>
    ),
    guest: (
      <p className={style.roleDescription}>
        Guests can only see the projects they are invited to, whether the
        project is public or private.
      </p>
    ),
    admin: (
      <p className={style.roleDescription}>
        Administrators are responsible for managing the Abstract account for an
        organization. They manage approval for new contributor seats, billing,
        integrations, organization details, and other organization-level
        settings.
      </p>
    ),
  };

  const { newSeatType, newOrganizationRole } = changes;

  // using a set so that we don't display the same role description twice
  const newSeatTypes = [...new Set([newSeatType])];

  return (
    <React.Fragment>
      <p className={style.confirmationLabel}>
        Changes for:{" "}
        <span className={style.confirmationValue}>{user.name}</span>
      </p>

      {newOrganizationRole && (
        <p className={style.confirmationLabel}>
          New organization role:{" "}
          <span className={style.confirmationValue}>
            {upperFirst(newOrganizationRole)}
          </span>
        </p>
      )}

      {newSeatType && (
        <p className={style.confirmationLabel}>
          New seat type:{" "}
          <span className={style.confirmationValue}>
            {upperFirst(newSeatType)}
          </span>
        </p>
      )}

      {newOrganizationRole && ROLE_DESCRIPTONS[newOrganizationRole]}

      {newSeatTypes.map((seatType) => {
        if (!seatType) {
          return null;
        }
        return SEAT_DESCRIPTIONS[seatType];
      })}
    </React.Fragment>
  );
};

// replace `owner` with `admin` for purposes of displaying "admin" in the UI
const swapOwnerWithAdmin = (v) => (v === "owner" ? "admin" : v);

// replace `admin` with `owner` eg. before submission back to API
const swapAdminWithOwner = (v) => (v === "admin" ? "owner" : v);

const EditPermissionsModal = ({
  open,
  close,
  subscription,
  user,
  organizationId,
}: Props) => {
  const { totalSeats, totalSeatsPending, usedSeats } = subscription;

  const dispatch = useDispatch();

  const {
    role: currentOrganizationRole,
    subscriptionRole: currentSubscriptionRole,
    createdAt: membershipCreatedAt,
  } = useSelector((state) =>
    getUserOrganizationMembership(state, {
      userId: user.id,
      organizationId,
    })
  );

  const [modalPage, setModalPage] = React.useState<ModalPage>("edit");

  const [organizationRole, setOrgnizationRole] =
    React.useState<ModalOrganizationRole>(
      swapOwnerWithAdmin(currentOrganizationRole)
    );

  const [subscriptionRole, setSubcriptionRole] = React.useState(
    currentSubscriptionRole
  );

  const downgradingSeat =
    currentSubscriptionRole === "contributor" &&
    subscriptionRole === "viewer" &&
    subscription.type !== "enterprise";

  const upgradingSeat =
    currentSubscriptionRole === "viewer" && subscriptionRole === "contributor";

  const availableTotalSeats = totalSeatsPending || totalSeats;

  const preventUpgrade =
    availableTotalSeats - usedSeats + (downgradingSeat ? 1 : 0) <= 0 &&
    upgradingSeat; // subscriptionRole === "viewer";

  const roleRef = React.useRef();

  const subcriptionRoleRefNonHybridOrganization = React.useRef();

  const noChanges =
    organizationRole === swapOwnerWithAdmin(currentOrganizationRole) &&
    subscriptionRole === currentSubscriptionRole;

  const changes: PermissionChanges = (() => {
    return {
      newOrganizationRole:
        swapOwnerWithAdmin(currentOrganizationRole) === organizationRole
          ? false
          : organizationRole,
      newSeatType:
        currentSubscriptionRole === subscriptionRole ? false : subscriptionRole,
    };
  })();

  const handleSubmit = () => {
    close();

    dispatch(
      UpdateOrganizationMembershipsRequest.perform({
        params: {
          userId: user.id,
          organizationId,
          membership: {
            userId: user.id,
            role: swapAdminWithOwner(organizationRole),
            subscriptionRole,
            createdAt: membershipCreatedAt,
            organizationId,
          },
        },
        onSuccess() {
          dispatch(
            SubscriptionFetchRequest.perform({
              params: { organizationId },
            })
          );
        },
      })
    );
  };

  return (
    <Modal
      isOpen={open}
      onClose={close}
      headerClassName={style.permissionsModalHeaderText}
      title={
        <div className={style.permissionsModalHeader}>Edit permissions</div>
      }
      footer={
        <Flex
          align="center"
          justify="space-between"
          className={style.modalFooter}
        >
          <div>
            <Button
              onClick={() => setModalPage("edit")}
              className={classnames(style.modalButton, {
                [style.visuallyHidden]: modalPage === "edit",
              })}
            >
              Back
            </Button>
          </div>

          <Flex justify="flex-end">
            <Button className={style.modalButton} onClick={close}>
              Cancel
            </Button>

            <span className={style.buttonSpacer} />

            <Button
              onClick={() => {
                if (modalPage === "edit") {
                  setModalPage("confirm");
                  return;
                } else if (modalPage === "confirm") {
                  handleSubmit();
                }
              }}
              disabled={noChanges || preventUpgrade}
              className={style.modalButton}
              qaSelector={modalPage === "confirm" ? "dialogSubmitButton" : ""}
              primary
            >
              {modalPage === "edit" ? "Next" : "Submit"}
            </Button>
          </Flex>
        </Flex>
      }
    >
      <div className={style.modalContent}>
        {modalPage === "edit" && (
          <React.Fragment>
            <Flex align="center" justify="space-between">
              <p style={{ flex: 2 }}>Role</p>
              <Flex style={{ flex: 1 }}>
                <ContextMenu
                  ref={roleRef}
                  placement="bottom-start"
                  id="role"
                  menuItems={[
                    {
                      label: "Member",
                      checked: organizationRole === "member",
                      click: () => setOrgnizationRole("member"),
                      type: "checkbox",
                    },
                    {
                      label: "Guest",
                      checked: organizationRole === "guest",
                      click: () => setOrgnizationRole("guest"),
                      type: "checkbox",
                    },
                    {
                      label: "Admin",
                      checked: organizationRole === "admin",
                      click: () => setOrgnizationRole("admin"),
                      type: "checkbox",
                    },
                  ]}
                >
                  {(showMenu, ref) => (
                    <Button
                      aria-haspopup="menu"
                      className={style.dropDown}
                      onClick={showMenu}
                      innerRef={ref}
                      disclosure
                      nude
                      qaSelector="role"
                    >
                      {upperFirst(organizationRole)}
                    </Button>
                  )}
                </ContextMenu>
              </Flex>
            </Flex>

            <React.Fragment>
              <Flex align="center" justify="space-between">
                <Flex align="center" style={{ flex: 2 }}>
                  <p>Seat type</p>
                  {preventUpgrade && <Icon type="warning" danger />}
                </Flex>
                <Flex style={{ flex: 1 }}>
                  <ContextMenu
                    id="non-hybrid-subscription-role"
                    ref={subcriptionRoleRefNonHybridOrganization}
                    menuItems={[
                      {
                        label: "Viewer",
                        click: () => setSubcriptionRole("viewer"),
                        type: "checkbox",
                        checked: subscriptionRole === "viewer",
                      },
                      {
                        label: "Contributor",
                        click: () => setSubcriptionRole("contributor"),
                        type: "checkbox",
                        checked: subscriptionRole === "contributor",
                      },
                    ]}
                  >
                    {(showMenu, ref) => (
                      <Button
                        aria-haspopup="menu"
                        className={style.dropDown}
                        onClick={showMenu}
                        innerRef={ref}
                        disclosure
                        nude
                        qaSelector="seat-type"
                      >
                        {upperFirst(subscriptionRole)}
                      </Button>
                    )}
                  </ContextMenu>
                </Flex>
              </Flex>

              {preventUpgrade && (
                <p className={style.preventUpgrade}>
                  You have <strong>0</strong> available contributor seats on
                  your account
                  <br />
                  <a
                    href={organizationBillingUrl(organizationId)}
                    className={style.copyLink}
                  >
                    Manage Account Settings
                  </a>
                </p>
              )}
            </React.Fragment>
          </React.Fragment>
        )}

        {modalPage === "confirm" && (
          <ConfirmationContent
            user={user}
            changes={changes}
            organizationId={organizationId}
            subscription={subscription}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditPermissionsModal;
