// @flow
import classnames from "classnames";
import reject from "lodash/reject";
import * as React from "react";
import { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import AutoCompleteListItem from "core/components/AutoCompleteListItem";
import theme from "core/components/InputRich/style.scss";
import InputWrapper from "core/components/InputWrapper";
import Spinner from "core/components/Spinner";
import filterUsers from "core/lib/filterUsers";
import KeyCode from "core/lib/keycode";
import type { ProjectMembershipListResponse } from "core/requests/projectMemberships";
import type { User } from "core/types";
import connector from "./connector";
import style from "./style.scss";

export type OwnProps = {|
  id?: string,
  label?: React.Node,
  placeholder?: string,
  onPick: (User) => void | Promise<void>,
  helpers?: { [string]: React.Node },
  responsive?: boolean,
  requiredTag?: boolean,
  wrapperClass?: string,
  selected?: boolean,
  value?: ?string,
  disabled?: boolean,
  className?: string,
  projectId: string,
  excludedUserIds?: string[],
|};

export type StateProps = {|
  isOnline: boolean,
|};

export type DispatchProps = {|
  onSearchForUsers: (
    search: string,
    offset: number
  ) => Promise<ProjectMembershipListResponse>,
|};

export type Props = {| ...OwnProps, ...StateProps, ...DispatchProps |};

type State = {|
  loading: boolean,
  noResults: boolean,
  nextOffset: number,
  prevValue: string,
  cacheBuster: string,
|};

const IGNORE_KEYCODES = [
  KeyCode.KEY_UP,
  KeyCode.KEY_DOWN,
  KeyCode.KEY_LEFT,
  KeyCode.KEY_RIGHT,
];
class InputUserSelect extends React.Component<Props, State> {
  static defaultProps = {
    requiredTag: false,
    helpers: {},
  };

  state = {
    loading: false,
    noResults: false,
    nextOffset: 0,
    prevValue: "",
    cacheBuster: "",
  };

  loadUserOptions = async (value: string) => {
    this.setState({ loading: true });

    const response = await this.props.onSearchForUsers(
      value,
      this.state.prevValue !== value ? 0 : this.state.nextOffset
    );
    if (!response) {
      return;
    }
    const { data, meta } = response;

    const users = data.map((membership) => membership.user);
    const withoutExcludedUsers = this.props.excludedUserIds
      ? reject(
          users,
          (user: User) =>
            this.props.excludedUserIds &&
            this.props.excludedUserIds.includes(user.id)
        )
      : users;
    const options = filterUsers(withoutExcludedUsers, value);

    this.setState({
      nextOffset: meta.next_offset,
      prevValue: value,
      loading: false,
      noResults: !options || !options.length,
    });
    return {
      options,
      hasMore: meta.next_offset > 0,
    };
  };

  // bust SelectAsyncPaginate's internal cache to force loading new options when input changes
  bustCache = (event: SyntheticInputEvent<>) => {
    this.setState({ cacheBuster: event.target.value });
  };

  handleInputKeyDown = (event: SyntheticKeyboardEvent<>) => {
    if (IGNORE_KEYCODES.includes(event.keyCode)) {
      return;
    }

    // $FlowFixMe event.keyCode exists in SyntheticKeyboardEvent, event.target.value exists in SyntheticInputEvent
    this.bustCache(event);
  };

  renderInput = () => {
    const noResultsText = this.props.isOnline
      ? "No results found"
      : "No results found. Results may be limited when offline.";

    const { disabled, className, value, onPick, placeholder } = this.props;
    return (
      <div
        className={classnames(style.wrapper, className)}
        data-qa="input-user-select"
      >
        <AsyncPaginate
          cacheUniqs={[this.state.cacheBuster]}
          isClearable={false}
          placeholder={placeholder}
          loadOptions={this.loadUserOptions}
          debounceTimeout={200}
          onInputKeyDown={this.handleInputKeyDown}
          components={{
            Option: ({ isFocused, isSelected, data, innerProps }) => (
              <AutoCompleteListItem
                className={classnames(
                  style.option,
                  theme.mentionSuggestionsEntry,
                  {
                    [theme.mentionSuggestionsEntryFocused]: isFocused,
                    "is-selected": isSelected,
                  }
                )}
                theme={theme}
                user={data}
                {...innerProps}
              />
            ),
            SingleValue: ({ children, ...props }) => (
              <components.SingleValue {...props}>
                {props.data.name}
              </components.SingleValue>
            ),
          }}
          value={value}
          disabled={disabled}
          onChange={onPick}
          cache={false}
          noResultsText={this.state.noResults ? noResultsText : "Loading…"}
          isMulti={false}
          className="Select_container"
          classNamePrefix="Select"
        />
      </div>
    );
  };

  render() {
    const { id, label, responsive, requiredTag, wrapperClass, helpers } =
      this.props;

    return (
      <InputWrapper
        inputId={id}
        label={label}
        helpers={helpers}
        responsive={responsive}
        requiredTag={requiredTag}
        className={wrapperClass}
      >
        {this.renderInput()}
        {this.state.loading && <Spinner small className={style.spinner} />}
      </InputWrapper>
    );
  }
}

export default connector(InputUserSelect);
